.footer {
  padding: 2rem 0;
  color: $black;
  font-size: 1.4rem;
}

.footer--bottom {
  justify-content: space-between;

  a, span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5.5rem;
    position: relative;
    margin: 0 1rem;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    font: 1.3rem $font-stack-title;
    letter-spacing: .2rem;
    color: $black;
    transition: .3s color ease;

    &:hover,
    &.is-active {
      color: $dore;
    }

  .footer--bottom__left {
    p {
      margin-bottom: 0;
    }
  }
  }
}
