
.header {
}

.header--top {
  @include respond-to("large-up") {
    width: 100%;
    justify-content: center;
    margin-bottom: 2rem;
  }
}

.header--logo {
  img {
    width: 10rem;

    @include respond-to("large-up") {
      width: 15rem;
    }
  }
}

.is-fixed {
  .header--top,
  .hamburger {
    position: relative;
    z-index: 2;
  }
  .header--list {
    display: flex;
    pointer-events: auto;
    opacity: 1;
    z-index: 1;
  }
}

.header--list {
  width: 100%;
  margin-bottom: 0;
  padding: 0;
  list-style: none;

  @include respond-to("large") {
    display: none;
    position: fixed;
    z-index: -1;
    overflow-x: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 9rem 0 0 0;
    pointer-events: none;
    opacity: 0;
    background: $white;
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    padding: 1rem;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    color: $black;
    font: 400 1.6rem $font-stack-title;
    letter-spacing: .2rem;
    transition: .3s color ease;

    &:hover,
    &.is-active {
      color: $dore;
    }
  }
}

.hamburger {
  margin-left: auto;

  &:focus {
    outline: none;
  }

  @include respond-to("large-up") {
    display: none;
  }
}

.hamburger-label {
  margin-right: 1.25rem;
  font: 1.3rem $font-stack-title;
  letter-spacing: .2rem;
  text-transform: uppercase;
}
