	// Fonts (Polices de caractère)

		// Source Sans Pro - Light (Exemple)

			// @font-face {
			//     font-family: 'Source Sans Pro';
			//     src: url('../fonts/sourcesans/sourcesanspro-light-webfont.woff2') format('woff2'),
			//          url('../fonts/sourcesans/sourcesanspro-light-webfont.woff') format('woff'),
			//          url('../fonts/sourcesans/sourcesanspro-light-webfont.ttf') format('truetype'),
			//     font-weight: 300;
			//     font-style: normal;
			// }

  // Playfair Display - Regular

  @font-face {
    font-family: 'Playfair Display';
    src: url('../fonts/playfairdisplay-regular.woff2') format('woff2'),
          url('../fonts/playfairdisplay-regular.woff') format('woff'),
          url('../fonts/playfairdisplay-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Open Sans - Regular

  @font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans-regular.woff2') format('woff2'),
          url('../fonts/opensans-regular.woff') format('woff'),
          url('../fonts/opensans-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  // Open Sans - Bold

    @font-face {
      font-family: 'Open Sans';
      src: url('../fonts/opensans-semibold.woff2') format('woff2'),
            url('../fonts/opensans-semibold.woff') format('woff'),
            url('../fonts/opensans-semibold.ttf') format('truetype');
      font-weight: 600;
      font-style: normal;
    }
