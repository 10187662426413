.personnalite--item {
  position: relative;

  h3 {
    width: 100%;
    color: $dore;
  }

  .personnalite--item__desc {
    font-size: 1.4rem;
    color: rgba($texte, .8);
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}
