.introduction {
  display: flex;
  align-items: center;
  position: relative;
  height: 40vh;
  background: {
    size: cover;
    repeat: no-repeat;
    position: 50%;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($black, .4);
  }

  &--sub {
    position: relative;
  }

  h1 {
    margin-bottom: 2rem;
    font: 3rem $font-stack-title;
    color: $white;
    line-height: 1;

    @include respond-to("large-up") {
      font-size: 5rem;
    }
  }

  p {
    color: $dore-clair;
    font: 400 3.5rem $font-stack-title;
  }
}
