
html {
  display: flex;
  flex-direction: column;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: $font-stack-text;
  color: $texte;
  line-height: 1.75;
}

main {
  flex: 1 1 auto;
}

ul {
  margin-bottom: 2rem;
}

figure {
  margin: 0 0 3rem;
}

figcaption {
  text-align: center;
  font-size: 1.3rem;
  color: rgba($black, .5);
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  font-family: $font-stack-title;
}

[class*="btn"] {
  font-family: $font-stack-title;
  text-transform: uppercase;
  letter-spacing: .2rem;
}

.iframe {
  position: relative;
}

.iframe--sub {
  position: relative;
  height: 0;
  padding: 0 0 56.25%; // Ratio 16/9

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.b-lazy {
  transition: opacity .3s ease-in-out;
  max-width: 100%;
  opacity: 0;

  &.b-loaded {
    opacity: 1;
  }
}

a {
  color: #b7a969;
}
