// Polices de caractères

  $font-stack-title: "Playfair Display", Arial, sans-serif;
  $font-stack-text: "Open Sans", Arial, sans-serif;

// Couleurs

  $texte: #2f2f2f !default;
  $dore: #b7a969 !default;
  $gris-dore: #f6f6f5 !default;
  $dore-clair: #dcd1a1 !default;
  $marron: #5d4a3a !default;
