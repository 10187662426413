.breadcrumbs {
  a {
    margin: 0 .75rem;
    font-size: 1.3rem;
    color: $dore;

    & + span {
      margin: 0 .75rem;
      font-size: 1.3rem;
    }
  }
}
