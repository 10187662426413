.page--item {
  position: relative;
  margin-bottom: 3rem;
  padding: 1.5rem;
  border: .1rem solid $dore;

  h2 {
    &:after {
      content: none;
    }
  }

  a {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
