.page {
  padding: 1rem 0;

  h1 {
    margin: 3rem 0 2rem;
  }
}

.page--left {
  @include respond-to("large-up") {
    width: calc(70% - 2rem);
    margin-right: 2rem;
  }
}

.page--right {
}
