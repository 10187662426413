.modular-section {
  padding: 5rem 0;

  h2 {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:after {
      content: "";
      width: 13rem;
      height: 2rem;
      margin-top: 1rem;
      background: {
        image: url('../img/ornement.svg');
        repeat: no-repeat;
      }
    }
  }
}

.modular--item {
  position: relative;
  margin-bottom: 3rem;

  &__horizontal {
    background-color: $gris-dore;

    .modular--item__contenu {
      padding: 2rem;

      @include respond-to("small-up") {
        flex: 1 1 0%;
      }
    }
  }

  &__vertical {
    .modular--item__image {
      margin-bottom: 2rem;
    }
  }

  h3 {
    width: 100%;
    color: $dore;
  }

  .modular--item__desc {
    font-size: 1.4rem;
    color: rgba($texte, .8);
  }

  .link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

