// Classes utilitaires

.is-fixed {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

p.important {
  font-size: 2rem;
  font-weight: 600;
}
