.entete {
  height: 20rem;
  align-items: center;

  h1 {
    font: 400 6rem $font-stack-title;
  }
}

.entete--sub {
  width: 100%;
}
